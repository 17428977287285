import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import DocumentTitle from 'react-document-title'
import App from './App'
import SignUpDialog from './SignUpDialog'
import VideoDialog from './VideoDialog';
import LoginDialog from './LoginDialog';
import ProfileDialog from './ProfileDialog';
import configureStore from '../reducers/configureStore'

const preloadedState = window.__PRELOADED_STATE__;
const store = configureStore(preloadedState);

const Root = () => (
  <Provider store={store}>
    <DocumentTitle title='Hansung Video'>
      <Router>
        <div>
          <Route exact path="/" render={()=>(<Redirect to='/videos'/>)} />
          <Route path="/videos" component={App} />
          <Route path='/videos/play' component={VideoDialog}/>
          <Route path="/videos/login" component={LoginDialog} />
          <Route path="/videos/signup" component={SignUpDialog} />
          <Route path="/videos/profile" component={ProfileDialog} />
        </div>
      </Router>
    </DocumentTitle>
  </Provider>
)

export default Root
