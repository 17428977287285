import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import VideoList from './VideoList';
import ImageList from './ImageList';
import TopBar from './TopBar';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { selectVideoListTab } from '../actions'

const styles = theme => ({
  back: {
    backgroundColor: '#DDDDDD',
    paddingBottom: 50,
  },
  root: {
    maxWidth: 960,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: '#EEEEEE',
  },

  main: {
    textAlign: 'center',
  },

  tabContainer: {
    minHeight: 1024,
  }
});

const StyledTabs = withStyles(theme => ({
  indicator: { backgroundColor: theme.additional.tertiary } 
}))(Tabs);

// const StyledLinearProgress = withStyles(theme => ({
//   colorSecondary: { backgroundColor: theme.additional.tertiary, },
// }))(LinearProgress);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};


class AppContainer extends React.Component {
  state = {
    tabValue: 0,
  };
  constructor(props) {
    super(props);
    let value = sessionStorage.getItem('AppTab');
    if(value === null) return;
    this.state.tabValue = parseInt(value);
    this.props.selectVideoListTab(parseInt(value));
  }
  componentWillMount() {
  }

  handleTabChange = (event, value) => {
    sessionStorage.setItem('AppTab', value);
    this.setState({ ...this.state, tabValue: value });
    this.props.selectVideoListTab(value);
  }

  render() {
    //TODO: redux dispatch를 쓰면, 한프레임 늦게 tabValue가 잡힌다. 왜그러지???
    //const { classes, videoListTab: tabValue} = this.props;
    const { classes } = this.props;
    const { tabValue } = this.state;

    return (
      <div className={classes.back}>
        <Paper className={classes.root}>
          <TopBar/>
          <AppBar position="static" 
            color="secondary">
            <StyledTabs value={tabValue} 
              onChange={this.handleTabChange}>
              <Tab label="한톡 2017" />
              <Tab label="한톡 2018" />
              <Tab label="모교방문" disabled/>
              <Tab label="송년회 2018" />
            </StyledTabs>
          </AppBar>
          {tabValue === 0 && <TabContainer>
            <div className={classes.tabContainer}>
              <VideoList tab={0} eventName="한톡 2017"/>
            </div>
          </TabContainer>}
          {tabValue === 1 && <TabContainer>
            <div className={classes.tabContainer}>
              <VideoList tab={1} eventName="한톡 2018"/>
            </div>
          </TabContainer>}
          {tabValue === 2 && <TabContainer>
            <div className={classes.tabContainer}>
              <VideoList tab={2} eventName="모교방문"/>
            </div>
          </TabContainer>}
          {tabValue === 3 && <TabContainer>
            <div className={classes.tabContainer}>
              <ImageList tab={3} eventName="YearEndParty2018"/>
            </div>
          </TabContainer>}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  videoListTab: state.video.videoListTab,
});
const mapDispatchToProps = {
  selectVideoListTab: selectVideoListTab,
};

AppContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppContainer)));
