import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { showVideo, } from '../../../actions';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import BlockIcon from '@material-ui/icons/Block';
import { Link } from 'react-router-dom'


const styles = theme => ({
  card: {
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'flex-start'
  },
  image: {
    flex: '0 0 auto',
    width: 120,
    height: 120,
    marginTop: 'auto',
    marginBottom: 'auto',
    overflow: 'hidden',
    paddingLeft: theme.spacing.unit,
    //filter: 'drop-shadow(0 0 0.1rem #2196f3)',
  },
  content: {
    flex: '0 1 auto',
    paddingTop: theme.spacing.unit*2,
    paddingBottom: theme.spacing.unit*2,
    paddingLeft: theme.spacing.unit*2,
    paddingRight: 0,
    justifyContent: 'flex-start',
  },
  controls: {
    flex: '0 0 auto',
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    paddingLeft: 0,
    paddingBottom: 0,
  },

  img: {
    margin: 'auto',
    display: 'block',
    width: '100%',
  },
  icon: {
    height: 28,
    width: 28,
  },
  name: {
    color: theme.additional.tertiary,
  },
});

class VideoCard extends Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.userLevel !== this.props.userLevel;
  }

  render()
  {
    const { classes, currentWidth, videoItem } = this.props;
    const { title, desc, access,
            owner: { name: ownerName, admission: ownerAdm, affiliation },
            event: { name: eventName, date: eventDate } } = videoItem;
    
    //350~560;
    let w = Math.max(0, currentWidth - 350);
    const maxLength = 41 + w/4;
    let shortDesc = desc;
    if(desc.length > maxLength)
      shortDesc = desc.slice(0,maxLength) + '...';

    let { thumbnail } = videoItem;
    if(thumbnail == null || thumbnail === '')
      thumbnail = "https://static.wixstatic.com/media/16666f_4ed60e989f8e43a492ee81554fc83fb1~mv2.png";

    return (
      <Card className={classes.card}>
        <ButtonBase className={classes.image} onClick={()=>{this.props.showVideo(videoItem)}}>
          <Link to='/videos/play'>
            <img className={classes.img} alt="Presenter" src={thumbnail}/>
          </Link>
        </ButtonBase>
        <div className={classes.content}>
          <Typography component="h6" variant="h6">
            {title}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {shortDesc}
          </Typography>
          <Typography variant="caption" className={classes.name}>
            [{ownerAdm}] {ownerName} ({affiliation})
          </Typography>
        </div>
        <div className={classes.controls}>
          <Link to='/videos/play'>
            <IconButton aria-label="Play" onClick={()=>{this.props.showVideo(videoItem)}}>
              {(access === 'restricted' && this.props.userLevel >= 1) || access === "public" ?
                <PlayArrowIcon className={classes.icon} /> :
                <BlockIcon className={classes.icon} />}
            </IconButton>
          </Link>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  userLevel: state.user.level,
});

const mapDispatchToProps = {
  showVideo: showVideo,
};

VideoCard.propTypes = {
  videoItem: PropTypes.object.isRequired,
  currentWidth: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoCard));
