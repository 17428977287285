import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom'
import Divider from '@material-ui/core/Divider';

import { restoreSession, showLoginDialog, showProfileDialog, logout } from '../../actions';
import hshsLogo from '../../assets/hshs-logo.png';
import PersonIcon from '@material-ui/icons/Person';
import AccountIcon from '@material-ui/icons/AccountCircle';
import SchoolIcon from '@material-ui/icons/School';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ExitIcon from '@material-ui/icons/ExitToApp';

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },

  appBar: {
    position: 'relative',
  },

  loginButton: {
    //boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.2) inset, 0px 2px 2px 0px rgba(0, 0, 0, 0.14) inset, 0px 3px 1px -2px rgba(0, 0, 0, 0.12) inset'
    color: '#FFFFFF',
  },

  accountButton: {
    margin: 0,
    padding: 0,
  },
  iconAccount: { color: '#FFFFFF', width: 30, height: 30 },

  accountStatus: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: 0,
    marginRight: theme.spacing.unit * 2,
  },

  linearProgress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 2,
    width:'100%',
  },

  iconLogin: { marginRight: theme.spacing.unit * 0.4, },

  logoButton: {
    marginLeft: -12,
    marginRight: 2,
  },

  logoImg: {
    width: 30,
    height: 30,
  },


  sessionAuthorization: {
    marginTop: 'auto',
    marginBottom: 'auto',
    marginRight: 5,
  },
  sessionNameStatus: {
    textAlign: 'center',
  },

  progress: {
    marginRight: theme.spacing.unit * 2,
    color: theme.additional.primaryContrast,
  },
});

class TopBarContainer extends Component {
  state = {
    anchorEl: null,
  };

  componentWillMount() {
    this.props.restoreSession();
  }
  componentDidMount() {
  }

  handleAccountMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAccountMenuLogout = () => {
    this.handleClose();
    this.props.logout();
  }

  handleShowProfileDialog = () => {
    this.handleClose();
    this.props.showProfileDialog();
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  userLevel = (classes) => {
    const level = this.props.userLevel;
    const loading = this.props.userLevelLoading; 
    let text = "";
    if(loading !== true && level > -1)
    {
      if(level === 0) text = "(동문 확인중)";
      else if(level === 1) text = "동문";
      else if(level >= 10) text = "관리자";
    }
    return (
      <Typography className={classes.sessionAuthorization} variant="caption" color="inherit">{text}</Typography>
    );
  }

  sessionStatus = (classes, isProgressing) => {
    const { anchorEl } = this.state;
    const level = this.props.userLevel;

    if(isProgressing) {
      return (<CircularProgress className={classes.progress}/>);
    } else {
      if(this.props.uid === '') { return (
        <Link to='/videos/login' style={{ textDecoration: 'none', color: '#FFFFFF'}}>
          <Button variant="text" color='secondary' className={classes.loginButton} 
            size="small" onClick={this.props.showLoginDialog}>
            <PersonIcon className={classes.iconLogin} />
            로그인 
          </Button >
        </Link>);
      } else { return (
        <div className={classes.accountStatus}>
          <IconButton color="primary" className={classes.accountButton}
            onClick={this.handleAccountMenuClick}>
            <AccountIcon className={classes.iconAccount}/>
          </IconButton>
          {this.userLevel(classes)}
          <Menu
            id="account-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose} >
            <MenuItem disabled style={{marginTop:0, paddingTop:0}}>
              <div style={{overflow:'hidden', margin:5, textAlign:'center'}}>
                <Typography style={{maxWidth: 100, margin:0, padding:0}} variant="body1">
                  {this.props.userName || this.props.userEmail}</Typography>
              </div>
            </MenuItem>
            <Divider/>
            <MenuItem component={Link} to="/videos/profile"
              disabled={level >= 1} onClick={this.handleShowProfileDialog}>
              <ListItemIcon className={classes.icon}>
                <SchoolIcon/>
              </ListItemIcon>
              동문인증
            </MenuItem>
            <MenuItem component={Link} to="/videos/profile" 
              onClick={this.handleShowProfileDialog}>
              <ListItemIcon className={classes.icon}>
                <FingerprintIcon/>
              </ListItemIcon>
              계정정보
            </MenuItem>
            <MenuItem onClick={this.handleAccountMenuLogout}>
              <ListItemIcon className={classes.icon}>
                <ExitIcon/>
              </ListItemIcon>
              로그아웃
            </MenuItem>
          </Menu>
        </div>
        );
      }
    }
  }

  /*
          <div className={classes.sessionNameStatus}>
            <Typography style={{marginBottom:0}} variant="body1" color="inherit">
              {this.props.user.displayName || this.props.user.email}</Typography>
            <Button size="small" style={{marginTop:0}} color="inherit" onClick={this.props.logout}>
              로그아웃 
            </Button>
          </div>
  */
  render() {
    const { classes, loadingVideoList, loadingImageList } = this.props;
    const isProgressing = (this.props.restoring || this.props.logingIn);
    //TODO : 유효한 tab 리스트를 다시 관리해야 함.
    const isLoadingList = (loadingImageList || loadingVideoList) && (this.props.videoListTab < 4);

    return (
      <AppBar className={classes.appBar} color='primary'>
        <Toolbar>
          <IconButton className={classes.logoButton} color="inherit" aria-label="Logo">
            <img className={classes.logoImg} alt="Logo" src={hshsLogo}/>
          </IconButton>
          <Typography variant="h6" color="inherit" className={classes.grow}>
            동문회 영상모음
          </Typography>
          {this.sessionStatus(classes, isProgressing)}
        </Toolbar>
        {isLoadingList?<LinearProgress className={classes.linearProgress}/>:null}
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  uid: state.auth.uid,
  restoring: state.auth.restoring,
  logingIn: state.auth.loading,

  userLevel: state.user.level,
  userLevelLoading: state.user.loading,
  userName: state.user.profile.name,
  userEmail: state.user.profile.email,

  loadingImageList: state.image.loadingList,
  loadingVideoList: state.video.loadingList,

  videoListTab: state.video.videoListTab,
});

const mapDispatchToProps = {
  restoreSession: restoreSession,
  showLoginDialog: showLoginDialog,
  showProfileDialog: showProfileDialog,
  logout: logout,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TopBarContainer));
