import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { closeLoginDialog, showSignUpDialog, loginByGoogle, loginByPassword } from '../../actions';
import googleLogo from '../../assets/google-logo.png';
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import TextFieldWE from '../TextFieldWE'

const styles = theme => ({
  loginDialogBack: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  loginDialog: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  appBar: {
    position: 'relative',
    backgroundColor: '#5C6BC0',
    paddingTop: 0,//theme.spacing.unit,
    paddingBottom: 0,//theme.spacing.unit,
    width: 400,
  },

  content: {
    overflowY:'hidden',
  },

  container: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    maxWidth: 280,
  },

  iconRegister: { marginRight: theme.spacing.unit * 0.5, },
  iconLogin: { marginRight: theme.spacing.unit * 0.25, },

  oAuthButton: {
    marginTop: theme.spacing.unit * 1.25,
    marginBottom: theme.spacing.unit * 1.25,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },

  logo: {
    width: 26,
    height: 26,
    marginRight: theme.spacing.unit,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class LoginDialogContainer extends Component
{
  valueEmail = {value:''};
  valuePassword = {value:''};
  initialState = {
    error: {
      emailTextField: false,
      passwordTextField: false,
      message: '',
    }
  };
  state = {...this.initialState};

  handleLogin = () => {
    this.setState({...this.state, error:{...this.initialState.error}});
    let e = this.valueEmail.value;
    let p = this.valuePassword.value;

    this.props.loginByPassword(e,p)
    .then( () => {
      this.props.closeLoginDialog();
    })
    .catch(error => {
      switch(error.code) {
        case 'auth/invalid-email': //Thrown if the email address is not valid.
          this.setState({...this.state, 
            error:{...this.state.error, emailTextField: true,
              message: '이메일을 다시 확인해주세요.'}});
        break;
        case 'auth/user-disabled': //Thrown if the user corresponding to the given email has been disabled.
          this.setState({...this.state,
            error:{...this.state.error, emailTextField: true,
              message: '사용 중지된 사용자입니다.'}});
        break;
        case 'auth/user-not-found': //Thrown if there is no user corresponding to the given email.
          this.setState({...this.state,
            error:{...this.state.error, emailTextField: true,
              message: '가입되지 않은 사용자입니다.'}});
        break;
        case 'auth/wrong-password': 
          this.setState({...this.state, 
            error:{...this.state.error, passwordTextField: true,
              message: '비밀번호를 다시 확인해주세요.'}});
        break;
        case 'inapp/user-not-verified': 
          this.setState({...this.state, 
            error:{...this.state.error, emailTextField: true,
              message: '가입 환영 메일을 확인해주세요.'}});
        break;
        default:
          this.setState({...this.state, 
            error:{...this.state.error, 
              message: error.code}});
      }
    });
  }

  handleSignUp = () => {
    this.props.closeLoginDialog();
    this.props.showSignUpDialog();
  }

  handleLoginByGoogle = () => {
    this.props.closeLoginDialog();
    this.props.loginByGoogle();
  }

  componentWillMount() {
    /*
     * iOS safari 스크롤 문제 해결을 위해 테스트
     *
    const { open } = this.props;
    if(open === true)
    {
      console.log("open", document.body.style.overflow);
      console.log(document);
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.height = "100%";
      document.documentElement.style.position = "relative";
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
      document.body.style.position = "relative";
    }
    */
  }
  componentDidMount() {
  }

  //handleClose = () => {
    /*
     * iOS safari 스크롤 문제 해결을 위해 테스트
     *
    console.log("close", document.body.style.overflow);
    document.documentElement.style = null;
    document.body.style = null; 
    document.body.style.overflow = null; 
    console.log(document);
    */
    //this.props.history.goBack();
    //this.props.closeLoginDialog();
  //}

  needBack = (element) =>
    <Modal className={this.props.classes.loginDialogBack} open={true}>
      {element}
    </Modal>

  render() {
    const { open, classes } = this.props;
    const isProgressing = (this.props.restoring || this.props.logingIn);
    if(open === false) return (<Redirect to='/videos'/>);

    return /*this.needBack*/(
      <Dialog className={classes.loginDialog}
        open={open}
        TransitionComponent={Transition}
        onClose={this.props.closeLoginDialog}
        aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar style={{minHeight:0}}>
            <IconButton color="inherit" style={{ marginLeft: -15, marginRight: 10 }}
              onClick={this.props.closeLoginDialog} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              한성과학고 동문회
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.content}>
          <form className={classes.container} noValidate autoComplete="on">
            <TextFieldWE fullWidth id="email" name="email" type="email" autoComplete="email" 
              label="이메일" margin="dense" valueObj={this.valueEmail}
              error={this.state.error.emailTextField} errorMessage={this.state.error.message}
              onChange={(e) => {
                this.setState({...this.state, error: {...this.state.error, emailTextField: false}});}}/>
            <br/>
            <TextFieldWE fullWidth id="password" name="password" type="password" 
              autoComplete="current-password" label="비밀번호" margin="dense" valueObj={this.valuePassword}
              error={this.state.error.passwordTextField} errorMessage={this.state.error.message}
              onChange={(e) => {
                this.setState({...this.state, error: {...this.state.error, passwordTextField: false}});}}/>
          </form>
        </DialogContent>
        <DialogActions style={{justifyContent: 'space-around'}}>
          <Button color="primary" disabled={isProgressing}
            component={Link} to="/videos/signup"
            onClick={this.handleSignUp}>
            <GroupIcon className={classes.iconRegister}/>
            가입하기
          </Button>
          <Button color="primary" disabled={isProgressing}
            onClick={this.handleLogin}>
            <PersonIcon className={classes.iconLogin}/>
            로그인
          </Button>
        </DialogActions>
        <Divider />
        <Button className={classes.oAuthButton} variant="contained" color="primary"
          onClick={this.handleLoginByGoogle}>
          <img className={classes.logo} alt="G" src={googleLogo}/>
          Sign in with Google
        </Button>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  open: state.user.openLoginDialog,

  restoring: state.auth.restoring,
  logingIn: state.auth.loading,
});

const mapDispatchToProps = {
  showSignUpDialog: showSignUpDialog,
  closeLoginDialog: closeLoginDialog,
  loginByPassword: loginByPassword,
  loginByGoogle: loginByGoogle,
};

LoginDialogContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginDialogContainer));
