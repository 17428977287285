const initialState = {
  openVideo: false,
  video: {},
  loadingList: false,
  videoList: null,
  videoListTab: 0,
};

const videoReducer = (state = initialState, action) => {
  switch(action.type)
  {
  case "SHOW_VIDEO":
    return { ...state, openVideo: true, video: {...action.video}};
  case "CLOSE_VIDEO":
    return { ...state, openVideo: false, };
  case "VIDEOLIST_LOADING":
    return { ...state, loadingList: true, }
  case "VIDEOLIST_LOADED":
    return { ...state, loadingList: false, videoList: [...action.videoList]}
  case "VIDEOLIST_ERROR":
    return { ...state, loadingList: false, videoList: null}
  case "SELECT_VIDEOLIST_TAB":
    return { ...state, videoListTab: action.videoListTab}
  default:
    return state;
  }
};

export default videoReducer;