import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import { closeSignUpDialog, signUpUser } from '../../actions';
import googleLogo from '../../assets/google-logo.png';
import { Redirect } from 'react-router-dom'
import TextFieldWE from '../TextFieldWE'

const styles = theme => ({
  loginDialogBack: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  loginDialog: {
    minWidth: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  appBar: {
    position: 'relative',
    backgroundColor: '#5C6BC0',
    paddingTop: 0,//theme.spacing.unit,
    paddingBottom: 0,//theme.spacing.unit,
    minWidth: 350,
  },

  text: {
    maxWidth: 280,
    marginTop: 40,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },

  container: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    maxWidth: 280,
  },

  oAuthButton: {
    marginTop: theme.spacing.unit * 1.25,
    marginBottom: theme.spacing.unit * 1.25,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
  },

  butttonSignUp: { marginRight: 15, marginBottom: 10 },
  iconSignUp: { marginRight: theme.spacing.unit * 0.5, },

  logo: {
    width: 26,
    height: 26,
    marginRight: theme.spacing.unit,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class SignUpDialogContainer extends Component
{
  valueEmail = {value:''};
  valuePassword = {value:''};
  valuePasswordConfirm = {value:''};
  initialState = {
    showSuccessDialog: false,
    error: {
      emailTextField: false,
      passwordTextField: false,
      passwordConfirmTextField: false,
      message: '',
    }
  };
  state = {...this.initialState};

  handleSignUp = () => {
    this.setState({...this.state, error:{...this.initialState.error}});
    let e = this.valueEmail.value;
    let p = this.valuePassword.value;
    let pc = this.valuePasswordConfirm.value;

    if(p!== pc) {
      this.setState({...this.state, 
        error:{...this.state.error, passwordConfirmTextField: true,
          message: '비밀번호가 다릅니다.'}});
      return;
    }

    this.props.signUpUser(e,p)
    .then( result => {
      this.setState({...this.state, showSuccessDialog: true,});
    })
    .catch(error => {
      switch(error.code) {
        case 'auth/invalid-email': //Thrown if the email address is not valid.
          this.setState({...this.state, 
            error:{...this.state.error, emailTextField: true,
              message: '이메일을 다시 확인해주세요.'}});
        break;
        case 'auth/email-already-in-use': //Thrown if there already exists an account with the given email address.
          this.setState({...this.state, 
            error:{...this.state.error, emailTextField: true,
              message: '이미 가입되어 있습니다.'}});
        break;
        case 'auth/operation-not-allowed': //Thrown if email/password accounts are not enabled. Enable email/password accounts in the Firebase Console, under the Auth tab.
          this.setState({...this.state,
            error:{...this.state.error, emailTextField: true,
              message: '일시적으로 가입이 중단된 상태입니다.'}});
        break;
        case 'auth/weak-password': //Thrown if the password is not strong enough.
          this.setState({...this.state,
            error:{...this.state.error, passwordTextField: true,
              message: '비밀번호가 너무 단순합니다.'}});
        break;
        default:
          this.setState({...this.state, 
            error:{...this.state.error, 
              message: error.code}});
      }
    });
  }
  componentDidMount() {
  }

  renderSuccessDialog = () => {
    return(
      <Dialog
        open={true}
        onClose={this.props.closeSignUpDialog} >
        <DialogTitle>{"가입을 환영합니다."}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            이제 곧 가입한 주소로 환영 메일이 도착할 것입니다. 
            <br/>
            확인해 주시면, 가입이 완료됩니다.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.closeSignUpDialog} color="primary">
            확인
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  needBack = (element) =>
    <Modal className={this.props.classes.loginDialogBack} open={true}>
      {element}
    </Modal>

  render() {
    const { open, classes } = this.props;
    const isProgressing = (this.props.restoring || this.props.logingIn);
    if(open === false) return (<Redirect to='/videos'/>);
    if(this.state.showSuccessDialog == true) {
      return this.renderSuccessDialog();
    }
    return /*this.needBack*/(
      <Dialog className={classes.loginDialog}
        open={open}
        TransitionComponent={Transition}
        onClose={this.props.closeSignUpDialog}
        aria-labelledby="form-dialog-title">
        <AppBar className={classes.appBar}>
          <Toolbar style={{minHeight:0}}>
            <IconButton color="inherit" style={{ marginLeft: -15, marginRight: 10 }}
              onClick={this.props.closeSignUpDialog} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              한성과학고 동문회 가입하기
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DialogContentText className={classes.text}>
            한성과학고 동문회 영상모음 사이트에 오신것을 진심으로 환영합니다.
          </DialogContentText>
          <form className={classes.container} noValidate autoComplete="off">
            <TextFieldWE fullWidth id="email" name="email" type="email" autoComplete="email" 
              label="이메일" margin="dense" valueObj={this.valueEmail}
              error={this.state.error.emailTextField} errorMessage={this.state.error.message}
              onChange={(e) => {
                this.setState({...this.state, error: {...this.state.error, emailTextField: false}});}}/>
            <br/>
            <TextFieldWE fullWidth id="password" name="password" type="password" 
              autoComplete="new-password" label="비밀번호" margin="dense" valueObj={this.valuePassword}
              error={this.state.error.passwordTextField} errorMessage={this.state.error.message}
              onChange={(e) => {
                this.setState({...this.state, error: {...this.state.error, passwordTextField: false}});}}/>
            <br/>
            <TextFieldWE fullWidth id="passwordConfirm" name="passwordConfirm" type="password" 
              autoComplete="new-password" label="비밀번호 확인" margin="dense" valueObj={this.valuePasswordConfirm}
              error={this.state.error.passwordConfirmTextField} errorMessage={this.state.error.message}
              onChange={(e) => {
                this.setState({...this.state, error: {...this.state.error, passwordConfirmTextField: false}});}}/>
          </form>
        </DialogContent>
        <DialogActions>
          <Button className={classes.butttonSignUp} disabled={isProgressing} 
            onClick={this.handleSignUp} color="primary">
            <GroupIcon className={classes.iconSignUp}/>
            가입하기
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  open: state.user.openSignUpDialog,

  restoring: state.auth.restoring,
  logingIn: state.auth.loading,
});

const mapDispatchToProps = {
  closeSignUpDialog: closeSignUpDialog,
  signUpUser: signUpUser, 
};

SignUpDialogContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SignUpDialogContainer));
