import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { showVideo, loadVideoList } from '../../actions';
import VideoCard from './VideoCard'

const styles = theme => ({
  videos: {
    maxWidth: 560,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  video: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 20,
    //marginLeft: 'auto',
    //marginRight: 'auto',
    marginBottom: 50,
  },

  videoTitle: { 
    maxWidth: 300,
    marginRight: 'auto'},
  videoBtns: { marginLeft: 'auto' },
});

class VideoListContainer extends Component {
  componentWillMount() {
    this.list = null;
  }

  componentDidMount() {
    this.props.loadVideoList(this.props.tab, this.props.eventName);
  }

  shouldComponentUpdate(nextProps) {
    let array1 = this.props.videoList;
    let array2 = nextProps.videoList;
    if(array1 === null || array1 === undefined) return true;
    if(array2 === null || array2 === undefined) return true;
    const propsSame = array1.length === array2.length && 
      array1.every(function(value, index) { 
        //console.log(value, array2[index]);
        //console.log(value == array2[index], value === array2[index]);
        return value === array2[index]})
    //console.log(propsSame, array1, array2);

    return !propsSame;
  }

  emptyMessage = () => (
    <div style={{textAlign: 'center'}}>
      영상 준비중...
    </div>
  )

  render()
  {
    let index = 0;
    const { classes, videoList, videoListLoading } = this.props;
    let empty = (videoListLoading === true ) ||
      (videoList === null) ||
      (videoList.length === 0) ||
      (this.list === null);
    return (
      <div className={classes.videos}
        ref={(ele) => {this.list = ele;}}>
        {empty === false ? videoList.map(item => <VideoCard key = {index++} 
          videoItem={item} currentWidth={this.list.offsetWidth}/>):null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  videoList: state.video.videoList,
  videoListLoading: state.video.loadingList,
});
const mapDispatchToProps = {
  showVideo: showVideo,
  loadVideoList: loadVideoList,
};

VideoListContainer.propTypes = {
  tab: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoListContainer));
