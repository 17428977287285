const initialState = {
  loadingList: false,
  imageList: null,
};

const imageReducer = (state = initialState, action) => {
  switch(action.type)
  {
  case "IMAGELIST_LOADING":
    return { ...state, loadingList: true, }
  case "IMAGELIST_LOADED":
    return { ...state, loadingList: false, imageList: [...action.imageList]}
  case "IMAGELIST_ERROR":
    return { ...state, loadingList: false, imageList: null}
  default:
    return state;
  }
};

export default imageReducer;