import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import { closeVideo, getSecretKey } from '../../actions';
import CryptoJS from 'crypto-js';
import VimeoPlayer from '@vimeo/player';
import ReactDOM from 'react-dom';
import { Redirect } from 'react-router-dom'

const styles = theme => ({
  videoDialog: {
    maxWidth: 980,
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  appBar: {
    position: 'relative',
    backgroundColor: '#5C6BC0',
  },

  videoDialogContent: {
    maxWidth: 640,
    width: '100%',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
  },

  title: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 50,
  },

  paper: {
    maxWidth: 600,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 80,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
  },

  descContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 12,
  },

  desc: {
    textAlign: 'left',
  },

  authorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },

  icon: {
    height: 28,
    width: 28,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class VideoDialogContainer extends Component
{
  state = {
    showDesc: false,
  };

  componentDidMount() {
    this.play = false;
  }

  getVimeoid = () => {
    const { userLevel, video } = this.props;
    let vimeoid = video.vimeoid;
    let secretKey = getSecretKey();
    if(vimeoid === '' && userLevel >= 1 && secretKey !== '' && video.encVimeoid !== undefined) {
      vimeoid = CryptoJS.AES.decrypt(video.encVimeoid, secretKey)
      .toString(CryptoJS.enc.Utf8);
    }
    return vimeoid;
  }

  playVideo = (videoContainer, vimeoid, startTime) => {
    if(vimeoid === undefined || vimeoid === null) return;
    let element = ReactDOM.findDOMNode(videoContainer);
    if(element == null) return;

    if(startTime === undefined) startTime = 0;

    var options = {
        id: vimeoid,
        loop: true,
        responsive: true,
        autopause: false,
    };

    var player = new VimeoPlayer(element, options);
    player.ready()
    .then(() => {
      this.setState({showDesc: true});
      player.setCurrentTime(startTime);
      player.play();
    })
    .catch(() => {
      this.setState({showDesc: true});
    });

    this.play = true;
  }

  close = () => {
    this.props.history.goBack();
    this.props.closeVideo();
  }

  render() {
    const { open, video, classes } = this.props;
    if(open === false || video['title'] === undefined) return (<Redirect to='/videos'/>);

    const { title, desc, access,
      owner: { name: ownerName, admission: ownerAdm, affiliation },
      event: { name: eventName, date: eventDate } } = video;

    let { startTime } = video;
    const vimeoid = this.getVimeoid();

    if (vimeoid === '') {
      if(access === '' || access === 'disabled') {
        return (
          <Dialog open={open} onClose={this.close}>
            <DialogTitle>
              비공개 비디오
            </DialogTitle>
            <DialogContent>
              <DialogContentText>강연자가 공개를 허락하지 않은 내용입니다.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.close}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        )
      } else {
        return (
          <Dialog open={open} onClose={this.close}>
            <DialogTitle>
              동문 공개 비디오
            </DialogTitle>
            <DialogContent>
              <DialogContentText>동문 외 비공개 내용은 회원가입 및 인증 후 보실 수 있습니다.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.close}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
    }

    return (
      <Dialog fullScreen className={classes.videoDialog}
        TransitionComponent={Transition}
        open={open} 
        onClose={this.close}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.close} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {video.title}
            </Typography>
          </Toolbar>
        </AppBar>
        <div id="videoContainer" className={classes.videoDialogContent}/*className='embed-container'*/
          ref={ele => {
            if(this.play === false) this.playVideo(ele, vimeoid, startTime);}}>
        </div>
        <div className={classes.paper} hidden={!this.state.showDesc}>
          <div className={classes.descContainer}>
            <Typography className={classes.desc} variant="subtitle1" color="textSecondary">
              {desc}
            </Typography>
          </div>
          <div className={classes.authorContainer}>
            <Typography variant="caption">
              [{ownerAdm}] {ownerName} ({affiliation})
            </Typography>
          </div>
        </div>
      </Dialog>
    );
  }
}
/*
            <iframe src={url} frameBorder='0' allow='autoplay; fullscreen' 
               webkitallowfullscreen='true' allowsinlinemediaplayback='true' 
               mozallowfullscreen='true' allowFullScreen>
            </iframe>
*/

VideoDialogContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  video: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  open: state.video.openVideo,
  video: state.video.video,
  userLevel: state.user.level,
});

const mapDispatchToProps = {
  closeVideo: closeVideo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VideoDialogContainer));
