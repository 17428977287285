const initialState = {
  restoring: false,
  loading: false,
  uid: '',
  error: null,
}

const authReducer = (state = initialState, action) => {
  switch(action.type) {
    case "SESSION_RESTORING":
      return { ...state, restoring: true, error: null }
    case "SESSION_LOADING":
      return { ...state, restoring: false, loading: true, error: null }
    case "SESSION_SUCCESS":
      return { restoring: false, loading: false, uid: action.uid, error: null }
    case "SESSION_ERROR":
      return { restoring: false, loading: false, uid: '', error: action.error }
    case "SESSION_LOGOUT":
      return initialState
    default:
      return state
  }
};

export default authReducer;
