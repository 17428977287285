import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  errorMessage: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: theme.spacing.unit * 0.2,
    marginRight: theme.spacing.unit * 0.2,
  },
});

class TextFieldWE extends Component {
  handleOnChange = (e) => {
    if(this.props.onChange !== undefined)
      this.props.onChange(e);
    this.props.valueObj.value = e.target.value;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <TextField 
          id={this.props.id}
          name={this.props.name}
          type={this.props.type}
          autoComplete={this.props.autoComplete}
          label={this.props.label}
          margin={this.props.margin}
          fullWidth={this.props.fullWidth}
          onChange={this.handleOnChange}
          error={this.props.error}
        />
        { (this.props.error && this.props.errorMessage !== '') ? (
          <Typography variant="caption" color="error" className={classes.errorMessage}> 
          * {this.props.errorMessage}
          </Typography>) : null }
      </div>
    );
  }
}

TextFieldWE.propTypes = {
  classes: PropTypes.object.isRequired,

  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  label: PropTypes.string.isRequired,
  margin: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,

  valueObj: PropTypes.object.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default withStyles(styles)(TextFieldWE);