import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import Slide from '@material-ui/core/Slide';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { closeProfileDialog, updateUserProfile, } from '../../actions';
import { Redirect } from 'react-router-dom'

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';


const styles = theme => ({
  loginDialogBack: {
    position: 'absolute',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  loginDialog: {
    minWidth: 200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  appBar: {
    position: 'relative',
    backgroundColor: '#5C6BC0',
    paddingTop: 0,//theme.spacing.unit,
    paddingBottom: 0,//theme.spacing.unit,
    minWidth: 350,
  },

  content: {
    paddingLeft: theme.spacing.unit * 1.5,
    paddingRight: theme.spacing.unit * 1.5,
  },

  text: {
    maxWidth: 320,
    marginTop: 20,
    marginLeft: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit * 2,
  },

  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit,
    maxWidth: 320,
  },

  butttonApply: { marginRight: theme.spacing.unit * 2, marginTop: 'auto', marginBottom: 'auto' },
  iconApply: { marginRight: theme.spacing.unit * 0.75, },

  logo: {
    width: 26,
    height: 26,
    marginRight: theme.spacing.unit,
  },
});

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ProfileDialogContainer extends Component
{
  initialState = {
    profile: {
      email: '',
      name: '',
      tel: '',
      birthdate: '1992-03-01',
      affiliation: '',
      university: '',
      address: '',

      admission: '',
      club: '',
      class1: '',
      class2: '',
      class3: '',
      greeting: '',
    },
    error: {
      name: false,
      tel: false,
      birthdate: false,
      affiliation: false,
      university: false,
      address: false,

      admission: false,
      club: false,
      class1: false,
      class2: false,
      class3: false,
      greeting: false,

      message: '',
    },
    showSuccessDialog: false,
  };
  state = {...this.initialState};

  format = (prop, value) => {
    switch(prop) {
      case 'admission':
        if(value === '') return '';
        value = value.substring(0,2);
        let num = parseInt(value, 10);
        if(isNaN(num)) return value;
        if(num <= 0 ) num = 1
        return num;
      default:
        return value;
    }

  }
  handleChange = prop => event => {
    let value = this.format(prop, event.target.value);
    this.setState(prevState => {
      prevState.profile[prop] = value;
      return prevState;
    });
  };
  handleDateChange = prop => date => {
    let value = date.toISOString().split('T')[0];
    this.setState(prevState => {
      prevState.profile[prop] = value;
      return prevState;
    });
  };

  checkDifferent = () => {
    let l = this.state.profile;
    let r = this.props.userProfile;
    let result = false;
    Object.keys(l).some((key, index) => {
      let lvalue = l[key];
      let rvalue = r[key];
      if(lvalue === undefined || lvalue === null) lvalue = '';
      if(rvalue === undefined || rvalue === null) rvalue = '';
      if(lvalue !== rvalue) {
        result = true;
        return true;
      }
      return false;
    });
    return result;
  }

  handleApply = () => {
    this.props.updateUserProfile(JSON.parse(JSON.stringify(this.state.profile)));
  }

  componentWillMount() {
    this.setState({profile:{...this.state.profile, ...this.props.userProfile}});
  }

  needBack = (element) =>
    <Modal className={this.props.classes.loginDialogBack} open={true}>
      {element}
    </Modal>

  render() {
    const { open, classes } = this.props;
    const isAppliable = this.checkDifferent() && !this.props.profileUpdating;
    if(open === false) return (<Redirect to='/videos'/>);
    if(this.state.showSuccessDialog === true) {
      return this.renderSuccessDialog();
    }
    return /*this.needBack*/(
      <Dialog className={classes.loginDialog}
        open={open}
        TransitionComponent={Transition}
        onClose={this.props.closeProfileDialog}>
        <AppBar className={classes.appBar}>
          <Toolbar style={{minHeight:0}}>
            <IconButton color="inherit" style={{ marginLeft: -15, marginRight: 10 }}
              onClick={this.props.closeProfileDialog} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              동문 정보 입력
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent className={classes.content}>
          {this.props.userLevel < 1 ?
            <DialogContentText className={classes.text}>
              동문 정보를 입력하시면, 자동으로 인증절차가 시작됩니다.
              동문 외 비공개 자료는 인증 후 접근할 수 있습니다.
              번거로우시더라도 꼭 입력 부탁드립니다.
            </DialogContentText> : null}
          <form className={classes.container} noValidate autoComplete="off">
            <TextField style={{width:100}}
              id="name" name="name" type="name" autoComplete="name" 
              label="이름" margin="dense" variant="outlined"
              value={this.state.profile.name} onChange={this.handleChange('name')}/>
            <TextField style={{width:150, float:'right'}} 
              id="tel" name="tel" type="tel" autoComplete="tel" 
              label="전화번호" margin="dense" variant="outlined"
              value={this.state.profile.tel} onChange={this.handleChange('tel')}/>
            <br/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker variant="outlined"
                id="birthdate" name="birthdate"
                label="생년월일" margin="dense"
                format="yyyy-MM-dd"
                mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                value={this.state.profile.birthdate} onChange={this.handleDateChange('birthdate')}/>
            </MuiPickersUtilsProvider>
            <br/>
            <TextField fullWidth id="affiliation" name="affiliation" type="text" autoComplete="organization" 
              label="소속" margin="dense" variant="outlined" placeholder="e.g. 동신한방병원"
              value={this.state.profile.affiliation} onChange={this.handleChange('affiliation')}/>
            <br/>
            <TextField fullWidth id="university" name="university" type="text"
              label="학부 진학 대학교" margin="dense" variant="outlined" placeholder="e.g. 서울대학교"
              value={this.state.profile.university} onChange={this.handleChange('university')}/>
            <br/>
            <TextField fullWidth id="address" name="address" type="text" autoComplete="address-level1" 
              label="주소" margin="dense" variant="outlined" placeholder="e.g. 경기도 성남시 분당구"
              value={this.state.profile.address} onChange={this.handleChange('address')}/>
            <br/>
            <br/>
            <TextField style={{width:80}} 
              id="admission" name="admission" type="number"
              label="기수" margin="dense" variant="outlined"
              value={this.state.profile.admission} onChange={this.handleChange('admission')}/>
            <TextField style={{width:160, float:'right'}}
              id="club" name="club" type="text"
              label="동아리" margin="dense" variant="outlined" placeholder="e.g. 폴라리스"
              value={this.state.profile.club} onChange={this.handleChange('club')}/>
            <br/>
            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
              <div><TextField style={{width:80}} 
                select
                id="class1" name="grade-class1" type="text"
                label="1학년" margin="dense" variant="outlined"
                value={this.state.profile.class1} onChange={this.handleChange('class1')}>
                {['1반', '2반', '3반', '4반', '5반', '6반', '모름']
                  .map(option => (<MenuItem key={option} value={option}> {option} </MenuItem>))}
              </TextField></div>
              <div><TextField style={{width:80, marginLeft:10, marginRight:10}} 
                select
                id="class2" name="grade-class2" type="text"
                label="2학년" margin="dense" variant="outlined"
                value={this.state.profile.class2} onChange={this.handleChange('class2')}>
                {['1반', '2반', '3반', '4반', '5반', '6반', '모름']
                  .map(option => (<MenuItem key={option} value={option}> {option} </MenuItem>))}
              </TextField></div>
              <div><TextField style={{width:80}} 
                select
                id="class3" name="grade-class3" type="text"
                label="3학년" margin="dense" variant="outlined"
                value={this.state.profile.class3} onChange={this.handleChange('class3')}>
                {['1반', '2반', '3반', '4반', '5반', '6반', '모름']
                  .map(option => (<MenuItem key={option} value={option}> {option} </MenuItem>))}
              </TextField></div>
            </div>
            <br/>
            <br/>
            <TextField fullWidth id="greeting" name="greeting" type="text"
              label="환영인사" margin="dense" variant="outlined"
              multiline rows="3"
              value={this.state.profile.greeting} onChange={this.handleChange('greeting')}/>
          </form>
        </DialogContent>
        <Divider/>
        <DialogActions>
          <Button className={classes.butttonApply} disabled={!isAppliable} 
            onClick={this.handleApply} color="primary">
            <SyncIcon className={classes.iconApply}/>
            {isAppliable ? '적용' : '완료'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  open: state.user.openProfileDialog,
  userLevel: state.user.level,
  userProfile: state.user.profile,
  profileUpdating: state.user.updating,
});

const mapDispatchToProps = {
  closeProfileDialog: closeProfileDialog,
  updateUserProfile: updateUserProfile,
};

ProfileDialogContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProfileDialogContainer));
