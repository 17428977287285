import firebaseService, { googleAuthProvider } from '../services/firebase'

export const showLoginDialog = () => {
  return (dispatch) => { dispatch({type: 'SHOW_LOGIN_DIALOG'}); } }

export const closeLoginDialog = () => {
  return (dispatch) => { dispatch({type: 'CLOSE_LOGIN_DIALOG'}); } }

export const showSignUpDialog = () => {
  return (dispatch) => { dispatch({type: 'SHOW_SIGNUP_DIALOG'}); } }

export const closeSignUpDialog = () => {
  return (dispatch) => { dispatch({type: 'CLOSE_SIGNUP_DIALOG'}); } }

export const showProfileDialog = () => {
  return (dispatch) => { dispatch({type: 'SHOW_PROFILE_DIALOG'}); } }

export const closeProfileDialog = () => {
  return (dispatch) => { dispatch({type: 'CLOSE_PROFILE_DIALOG'}); } }

export const selectVideoListTab = (tab) => {
  return (dispatch) => {
    dispatch({type: 'SELECT_VIDEOLIST_TAB',
      videoListTab: tab});
  }
}

export const showVideo = (video) => {
  return (dispatch) => {
    dispatch({type: 'SHOW_VIDEO',
      video: video});
  }
};

export const closeVideo = () => {
  return (dispatch) => {
    dispatch({type: 'CLOSE_VIDEO'});
  }
}

export const restoreSession = () => {
  return (dispatch) => {
    dispatch(sessionRestoring())
    secretKey = '';
    let unsubscribe = firebaseService.auth()
      .onAuthStateChanged(user => {
        if (user) {
          verifyEmail(dispatch, user);
          unsubscribe();
        } else {
          dispatch(sessionLogout());
          dispatch({type: "PROFILE_RESET_BY_LOGOUT"});
          unsubscribe();
        }
      })
  }
}

export const loginByPassword = (email, password) => {
  return (dispatch) => {
    dispatch(sessionLoading())

    return new Promise((resolve, reject) => {
      firebaseService.auth()
      .signInWithEmailAndPassword(email, password)
      .then(result => {
        if(verifyEmail(dispatch, result.user))
          resolve(result);
        else
          reject({
            code: 'inapp/user-not-verified',
            message: 'The user email is not yet verified.'
          })
      })
      .catch(function(error) {
        dispatch(sessionError(error.message));
        reject(error);
      });
    });
  }
}

export const loginByGoogle = () => {
  return (dispatch) => {
    dispatch(sessionLoading())

    firebaseService.auth().signInWithRedirect(googleAuthProvider);
    firebaseService.auth().getRedirectResult()
    .then(result => {
      let user = result.user;
      //dispatch(sessionSuccess(user));
    })
    .catch(error => {
      console.log(error);
      dispatch(sessionError(error.message));
    });

    /*
    let unsubscribe = firebaseService.auth()
    .onAuthStateChanged(user => {
      if (user) {
        dispatch(sessionSuccess(user))
        unsubscribe()
      }
    });
    */
  }
}

export const signUpUser = (email, password) => {
  return (dispatch) => {
    dispatch(sessionLoading())

    let promise = firebaseService.auth()
    .createUserWithEmailAndPassword(email, password);
    promise.then(result => {
      let user = result.user;
      user.sendEmailVerification();
      verifyEmail(dispatch, user);
    })
    .catch(error => {
      dispatch(sessionError(error.message));
    });

    return promise;
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch(sessionLoading())
    secretKey = '';

    firebaseService.auth()
      .signOut()
      .then(() => {
        dispatch(sessionLogout())
        dispatch({type: "PROFILE_RESET_BY_LOGOUT"});
      })
      .catch(error => {
        dispatch(sessionError(error.message))
      })
  }
}

export const loadVideoList = (tab, eventName) => (dispatch, getState) => {
  dispatch({type: "VIDEOLIST_LOADING"});

  let tabAtRequest = tab;
  //console.log("request", tab, getState().video.videoListTab);
  
  firebaseService.database().ref('/videos').once('value')
  .then(snapshot => {
    //console.log("response", tab, getState().video.videoListTab);
    let currentTab = getState().video.videoListTab;
    if(tabAtRequest !== currentTab) return;
    let value = snapshot.val();
    value = value.filter(item => item.event.name === eventName);
    if(value.length < 0) dispatch({type: "VIDEOLIST_ERROR"});
    else dispatch({type: "VIDEOLIST_LOADED", videoList: value})
  })
  .catch(error => {
    console.log(error);
    let currentTab = getState().video.videoListTab;
    if(tabAtRequest !== currentTab) return;
    dispatch({type: "VIDEOLIST_ERROR"});
  });
}

export const loadImageList = (tab, eventName) => (dispatch, getState) => {
  dispatch({type: "IMAGELIST_LOADING"});

  let tabAtRequest = tab;
  firebaseService.database().ref('/images/' + eventName).once('value')
  .then(snapshot => {
    let currentTab = getState().video.videoListTab;
    if(tabAtRequest !== currentTab) return;
    if(snapshot.val() === null) {
      dispatch({type: "IMAGELIST_ERROR"});
      return;
    }
    let list = Object.entries(snapshot.val()).map(([key, value]) => {
      value['key'] = key; return value;
    });
    if(list.length < 0) dispatch({type: "IMAGELIST_ERROR"});
    else dispatch({type: "IMAGELIST_LOADED", imageList: list})
  })
  .catch(error => {
    console.log(error);
    let currentTab = getState().video.videoListTab;
    if(tabAtRequest !== currentTab) return;
    dispatch({type: "IMAGELIST_ERROR"});
  });
}

export const addImageToLocalList = (key, image) => (dispatch, getState) => {
    let list = getState().image.imageList;
    if(list === null) list = [];
    image['key'] = key;
    list.push(image);
    dispatch({type: "IMAGELIST_LOADED", imageList: list});
}

export const deleteImage = (eventName, image) => (dispatch, getState) => {
    let list = getState().image.imageList;
    if(list === null) return;
    if(image === null || image === undefined) return;

    firebaseService.database().ref('/images/' + eventName + '/' + image.key).remove().then(() => {
      list = list.filter((item) => {
        return item.key !== image.key});
      dispatch({type: "IMAGELIST_LOADED", imageList: list})
    })
    .catch( () => {
      // nothing to do
    });
}

export const updateUserProfile = (profile) => (dispatch, getState) => {
  dispatch({type: "PROFILE_UPDATING"});
  const uid = getState().auth.uid;
  if(uid === '') {
    dispatch({type: "PROFILE_UPDATE_ERROR"});
    return;
  }

  firebaseService.database().ref('/users/' + uid + '/profile').set(profile, (error) => {
    if (error) {
      dispatch({type: "PROFILE_UPDATE_ERROR"});
    } else {
      dispatch({type: "PROFILE_UPDATED", profile});
    }
  });
}

/*********************************************************************/


let secretKey = '';
export const getSecretKey = () => secretKey;

const sessionRestoring = () => ({ type: "SESSION_RESTORING" })
const sessionLoading = () => ({ type: "SESSION_LOADING" })
const sessionSuccess = uid => ({ type: "SESSION_SUCCESS", uid })
const sessionError = error => ({ type: "SESSION_ERROR", error })
const sessionLogout = () => ({ type: "SESSION_LOGOUT" })

const verifyEmail = (dispatch, user) => {
  // TODO : emailverified 확인하여 적절한 처리
  const { emailVerified } = user;
  let provider='';
  if(user.providerData.length > 0) provider = user.providerData[0].providerId;

  if(provider !== 'google.com' || provider !== 'facebook.com') {
    if(emailVerified === false) {
      logout()(dispatch);
      return false;
    }
  }

  let simpleUser = {
    displayName: user.displayName,
    email: user.email,
    uid: user.uid,
    provider: provider,
  };

  dispatch(sessionSuccess(user.uid));
  setUserDataAtFirstTime(dispatch, simpleUser);
  return true;
}

const setUserDataAtFirstTime = (dispatch, user) => {
  const { displayName, email, uid, provider} = user;
  firebaseService.database().ref('/users/' + uid).once('value').then(snapshot => {
    let value = snapshot.val();
    if(value == null) {
      firebaseService.database().ref('/users/' + uid).set({
        level: 0,
        provider: provider,
        email: email,
        profile: {
          email: email,
          name: displayName,
          birthdate: '1992-03-01',
        }
      }, function(error) {
        if (error) {
          // The write failed...
        } else {
          loadProfile(dispatch, uid);
        }
      });
    } else {
      loadProfile(dispatch, uid);
    }
  });
}


/*
export const loadProfile = () => (dispatch, getState) => {
  dispatch({type: "PROFILE_LOADING"});
  const user = getState().auth.user;
  if(user === null || user === undefined) {
    dispatch({type: "PROFILE_ERROR"});
    return;
  }
  const { uid } = user;
  firebaseService.database().ref('/users/' + uid + '/profile').once('value')
  .then(snapshot => {
    let value = snapshot.val();
    console.log(value);
    dispatch({type: "PROFILE_LOADED", profile: value})
  })
  .catch(error => {
    console.log(error);
    dispatch({type: "PROFILE_ERROR"});
  });
}
*/

const loadProfile = (dispatch, uid) => {
  dispatch({type: "PROFILE_LOADING"});

  firebaseService.database().ref('/users/' + uid).once('value')
  .then(snapshot => {
    let value = snapshot.val();
    if(value === null || isNaN(value.level)) {
      dispatch({type: "PROFILE_LOAD_ERROR"});
      return;
    }

    if(value.level >= 1) { // 동문. 비밀키도 함께 읽어온다.
      firebaseService.database().ref('/secretKey/videos').once('value')
      .then(snapshot => {
        secretKey = snapshot.val();
        dispatch({type: "PROFILE_LOADED", level: value.level, profile: value.profile})
      })
      .catch(error => {
        console.log(error);
        dispatch({type: "PROFILE_LOAD_ERROR"});
      })
    } else {
      dispatch({type: "PROFILE_LOADED", level: value.level, profile: value.profile});
    }
  })
  .catch(error => {
    console.log(error);
    dispatch({type: "PROFILE_LOAD_ERROR"});
  });
}