import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
//import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import FileUploader from '../FileUploader'
import firebaseService from '../../services/firebase'
import UploadIcon from '@material-ui/icons/CloudUpload';
import LinearProgress from '@material-ui/core/LinearProgress';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import { loadImageList, addImageToLocalList, deleteImage } from '../../actions';

const styles = theme => ({
  images: {
    maxWidth: 560,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  input: {
    display: 'none',
  },
  buttonUpload: {
    padding: theme.spacing.unit * 0.25,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  iconUpload: { 
    position: 'relative',
    marginRight: theme.spacing.unit,
  },
  progress: {
  },
});

class ImageListContainer extends Component {
  constructor() {
    super();
    this.state = { 
      currentImage: 0,
      lightboxIsOpen: false,
      isUploading: false,
      showDeleteDialog: false,
      progress: 0, };
  }
  openLightbox = (event, obj) => {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious = () => {
    const { imageList } = this.props;
    this.setState(prevState => ({
      currentImage: (prevState.currentImage + imageList.length - 1) % imageList.length,
    }));
  }
  gotoNext = () => {
    const { imageList } = this.props;
    this.setState(prevState => ({
      currentImage: (prevState.currentImage + 1) % imageList.length,
    }));
  }

  handleUploadReady= () => {
    this.setState({ isUploading: true, progress: 0 });
  };
  handleUploadStart = (file, task) => {
    this.setState({ isUploading: true, progress: 0 });
  };
  handleProgress = (progress, task) => {
    let name = task.snapshot;

    //console.log("progress: ", name, progress);
    this.setState({ progress });
  };
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = (filename, thumbWidth, thumbHeight, width, height) => {
    const { eventName, userName, userEmail } = this.props;
    firebaseService.storage().ref("images/" + eventName).child(filename)
    .getDownloadURL().then(url => {
      firebaseService.storage().ref("images/" + eventName + "/thumbs").child(filename)
      .getDownloadURL().then(thumbUrl => {
        let newPostKey = firebaseService.database().ref('/images/' + eventName).push().key;
        let image = {
          width: thumbWidth, height: thumbHeight, 
          src: thumbUrl, bigsrc: url, filename };
        let uploader = userName;
        let caption = (userName === undefined) ? undefined : ('Uploaded by ' + uploader);
        if(caption !== undefined) image['caption'] = caption;
        firebaseService.database().ref('/images/' + eventName + '/' + newPostKey).set(image)
        .then( () => {
          this.setState({ progress: 100, isUploading: false });
          this.props.addImageToLocalList(newPostKey, image);
        })
        .catch( (error) => {
          this.setState({ isUploading: false });
          console.error(error);
        });
      })
      .catch( (error) => {
        this.setState({ isUploading: false });
        console.error(error);
      });
    })
    .catch( (error) => {
      this.setState({ isUploading: false });
      console.error(error);
    });
  };

  checkImage = () => {
    const { userLevel } = this.props;
    if( userLevel < 10 ) {
      this.closeLightbox();
      return;
    }
    this.setState({showDeleteDialog: true});
  }

  handleDeleteDialogClose = () => {
    this.setState({showDeleteDialog: false});
  }

  handleDelete = () => {
    const { eventName, userLevel, imageList } = this.props;
    const { currentImage } = this.state;
    if( userLevel < 10 ) return;
    this.setState({showDeleteDialog: false});
    this.props.deleteImage(eventName, imageList[currentImage]);

    if(imageList.length === 1) this.closeLightbox();
    else if(currentImage === imageList.length - 1) this.gotoPrevious();
  }



  componentWillMount() {
    this.list = null;
  }

  componentDidMount() {
    this.props.loadImageList(this.props.tab, this.props.eventName);
  }

  renderUpload = () => {
    const { classes, eventName, userLevel } = this.props;
    const { isUploading,  progress} = this.state;
    if( userLevel < 10 ) return null;

    if(isUploading) {
      return (
        <div>
        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={progress}/>
        </div>
      );
    }
        /*style={{backgroundColor: 'steelblue', color: 'white', cursor: 'pointer',
          padding: 10, borderRadius: 4}}*/
        /**/
    return (
      <div>
        <FileUploader
          className={classes.input}
          id="contained-button-file"
          accept="image/*"
          name="upload"
          multiple
          randomizeFilename
          storageRef={firebaseService.storage().ref('images/' + eventName)}
          thumbStorageRef={firebaseService.storage().ref('images/' + eventName + '/thumbs')}
          thumbMaxWidth={300}
          onUploadReady={this.handleUploadReady}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          onProgress={this.handleProgress}/>
        <label htmlFor="contained-button-file">
          <Button color="primary" variant="contained" component="span" className={classes.buttonUpload}>
            <UploadIcon className={classes.iconUpload} />
            관리자 전용 업로드 
          </Button>
        </label>
      </div>
    );
  }

  renderLightBox = () => {
    if(this.state.lightboxIsOpen === false) return null;
    const { imageList, userLevel } = this.props;
    const index = this.state.currentImage;
    const length = imageList.length;

    const deleteButton = userLevel < 10 ? null : (
      <Button variant="contained" color="primary" 
        style={{marginRight:20}}
        onClick={this.checkImage}>
        삭제 
      </Button>
    );

    return (
      <Lightbox
        reactModalStyle={{ overlay: { zIndex: 2001 }}}
        mainSrc={imageList[index].bigsrc}
        nextSrc={imageList[(index + 1) % length].bigsrc}
        prevSrc={imageList[(index + length - 1) % length].bigsrc}
        onCloseRequest={this.closeLightbox}
        onMovePrevRequest={this.gotoPrevious}
        onMoveNextRequest={this.gotoNext}
        onImageLoadError={this.closeLightbox}
        toolbarButtons={[deleteButton]}
      />
    );
  }

  render()
  {
    const { classes, imageList, imageListLoading } = this.props;
    const { showDeleteDialog } = this.state;

    let empty = (imageListLoading === true ) ||
      (imageList === null) ||
      (imageList.length === 0) ||
      (this.list === null);
    

    return (
      <div className={classes.images}
        ref={(ele) => {this.list = ele;}}>
        <Dialog open={showDeleteDialog} onClose={this.handleDeleteDialogClose}
          style={{zIndex: 2010}}>
          <DialogTitle>
            관리자 전용 모드
          </DialogTitle>
          <DialogContent>
            <DialogContentText>사진을 삭제하시겠습니까?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.handleDeleteDialogClose}>
              취소 
            </Button>
            <Button color="primary" onClick={this.handleDelete}>
              삭제 
            </Button>
          </DialogActions>
        </Dialog>
        {empty === false ? <div>
          <Gallery photos={imageList} columns={3} onClick={this.openLightbox} />
          {this.renderLightBox()}
        </div> : null}
        <br/>
        {this.renderUpload()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  imageList: state.image.imageList,
  imageListLoading: state.image.loadingList,

  userLevel: state.user.level,

  userName: state.user.profile.name,
  userEmail: state.user.profile.email,
});

const mapDispatchToProps = {
  loadImageList: loadImageList,
  addImageToLocalList: addImageToLocalList,
  deleteImage: deleteImage,
};

ImageListContainer.propTypes = {
  tab: PropTypes.number.isRequired,
  eventName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ImageListContainer));
