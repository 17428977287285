const initialState = {
  level: -1,
  loading: false,
  updating: false,
  profile: {},
  openLoginDialog: false,
  openSignUpDialog: false,
  openProfileDialog: false,
}

const userReducer = (state = initialState, action) => {
  switch(action.type) {
    case "SHOW_LOGIN_DIALOG":
      return { ...state, openLoginDialog: true, };
    case "CLOSE_LOGIN_DIALOG":
      return { ...state, openLoginDialog: false, };
    case "SHOW_SIGNUP_DIALOG":
      return { ...state, openSignUpDialog: true, };
    case "CLOSE_SIGNUP_DIALOG":
      return { ...state, openSignUpDialog: false, };
    case "SHOW_PROFILE_DIALOG":
      return { ...state, openProfileDialog: true, };
    case "CLOSE_PROFILE_DIALOG":
      return { ...state, openProfileDialog: false, };
    case "PROFILE_LOADING":
      return { ...state, loading: true, }
    case "PROFILE_LOADED":
      return { ...state, loading: false, level: action.level, profile: {...action.profile} }
    case "PROFILE_RESET_BY_LOGOUT":
      return { ...state, level: initialState.level, profile: initialState.profile }
    case "PROFILE_LOAD_ERROR":
      return initialState;
    case "PROFILE_UPDATING":
      return { ...state, updating: true }
    case "PROFILE_UPDATED":
      return { ...state, updating: false, profile: action.profile }
    case "PROFILE_UPDATE_ERROR":
      return { ...state, updating: false }
    default:
      return state;
  }
};

export default userReducer;
